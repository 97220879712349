import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.css']
})
export class OtpInputComponent implements OnInit {

  constructor() { }

  resendDisabled: boolean;
  resendTimer: number;
  validOTP: boolean = false;
  verificationCode: string = "";
  OTPConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  }
  @Input() otpTitle: string = "";
  @Input() userContact: string = "";
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter();
  @Output() onResendCode: EventEmitter<string> = new EventEmitter<string>();

  useBackButtonInCard: boolean = environment.useBackButtonInCard;
  showClientLogoAtLogin: boolean = environment.showClientLogoAtLogin;
  isDespnsa: boolean = environment.isDespnsa;

  ngOnInit(): void {
    this.resetDisabledTimer();
  }

  resetDisabledTimer() {
    this.resendDisabled = true;
    this.resendTimer = 30;
    let timer = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer == 0) {
        this.resendDisabled = false;
        clearInterval(timer);
      }
    }, 1000);
  }

  onOTPChange(otpCode: string) {
    this.verificationCode = otpCode;
    if (otpCode.length == 6) {
      this.validOTP = true;
    }
    else {
      this.validOTP = false;
    }
  }

  submit() {
    this.onSubmit.emit(this.verificationCode);
  }

  goBack() {
    this.onGoBack.emit();
  }

  resendCode(channel: string) {
    this.resetDisabledTimer();
    this.onResendCode.emit(channel);
  }

}
