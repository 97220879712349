import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { User, AcceptedPaymentMethods, PaymentMethod } from 'src/app/interfaces';
import { BalanceRechargeModalComponent } from 'src/app/private/pages/profile/balance-recharge-modal/balance-recharge-modal.component';
import { AmipassService } from 'src/app/services/amipass.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { TransbankService } from 'src/app/services/transbank.service';
import { environment } from 'src/environments/environment';

export interface CardInscriptionData {
  user: User;
  storeId: string;
  acceptedPaymentMethods: AcceptedPaymentMethods;
  origin: string
}

@Component({
  selector: 'app-card-inscription-selection',
  templateUrl: './card-inscription-selection.component.html',
  styleUrls: ['./card-inscription-selection.component.css']
})
export class CardInscriptionSelectionComponent implements OnInit {

  user: User;
  acceptedPaymentMethods: AcceptedPaymentMethods;
  storeId: string | null = null;
  mercadopagoAccepted: boolean = environment.paymentMethods.mercadopago2;
  transbankAccepted: boolean = environment.paymentMethods.transbank;
  saldoreiteAccepted: boolean = environment.paymentMethods.saldo_reite;
  useCardInscription!: boolean;
  useAmipass!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CardInscriptionData,
    private transbank: TransbankService,
    private router: Router,
    private amipassService: AmipassService,
    private analytics: AnalyticsService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,

  ) {
    this.user = data.user;
    this.storeId = data.storeId;
    this.acceptedPaymentMethods = data.acceptedPaymentMethods;
    // Check if the payment methods are registered by the user
    this.transbankAccepted = Object.keys(this.acceptedPaymentMethods).includes(PaymentMethod.TRANSBANK) && this.transbankAccepted;
    this.mercadopagoAccepted =  Object.keys(this.acceptedPaymentMethods).includes(PaymentMethod.MERCADOPAGO) && this.mercadopagoAccepted;
    this.saldoreiteAccepted = Object.keys(this.acceptedPaymentMethods).includes(PaymentMethod.SALDO_REITE) && this.saldoreiteAccepted;
  }

  ngOnInit(): void {
    this.analytics.logEvent("open_card_inscription", {"origin": this.data.origin});
    this.useCardInscription = environment.paymentMethods.transbank || environment.paymentMethods.mercadopago2;
    this.useAmipass = environment.paymentMethods.saldo_reite;
    if (!(this.useCardInscription || this.useAmipass)) {
      this.dialog.closeAll();
    }
  }

  mercadopagoInscription() {
    this.analytics.logEvent("start_card_inscription", {"payment_processor": "mercadopago", "origin": this.data.origin});
    this.router.navigate(
      ['mp-card-inscription'],
      { queryParams: {storeId: this.storeId },
        replaceUrl: false
      }
    );
  }

  transbankInscription() {
    this.analytics.logEvent("start_card_inscription", {"payment_processor": "transbank", "origin": this.data.origin});
    this.transbank.cardInscription(this.storeId);
  }

  openBalanceRechargeDialog() {
    const dialogRef = this.dialog.open(BalanceRechargeModalComponent)
    dialogRef.componentInstance.balanceRecharge.subscribe((amount: number) => {
      this.amipassService.amipassInit(amount, this.storeId, this.spinner, this._snackBar);
    });
  }

}
