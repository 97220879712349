<h2 mat-dialog-title class="mx-2">{{ 'components.verification-charge.title' | translate }}</h2>
<div mat-dialog-content class="mx-2">
    <h3 class="text-black">{{ 'components.verification-charge.info_1' | translate }}</h3>
    <div class="font-primary text-xl text-center text-black m-2">
      💵${{verificationAmount}}💵
    </div>
    <p class="text-black text-justify text-sm">
      {{ 'components.verification-charge.info_2' | translate }}&nbsp;
      {{ 'components.verification-charge.info_3' | translate }}&nbsp;
      <b>{{ 'components.verification-charge.info_4' | translate }}</b>
    </p>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>
