import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SideBarLink } from 'src/app/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { RouteTrackerService } from 'src/app/services/route-tracker.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() verified?: boolean;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()

  storeId: string = '';
  links!: SideBarLink[];

  constructor(
    private auth: AuthService,
    private router: Router,
    private routeTrackerService: RouteTrackerService,
    private analytics: AnalyticsService) {
      this.routeTrackerService.getStoreId().subscribe((storeId: string) => {
        this.storeId = storeId
        this.setLinks();
      });
    }

  ngOnInit(): void {
    this.setLinks();
  }

  setLinks() {
    this.links = [
      { title: 'components.sidebar.profile', icon: 'assets/icons/profile.svg', action: () => this.navigate(`/profile/${this.storeId}`) },
      { title: 'components.sidebar.history', icon: 'assets/icons/history.svg', action: () => this.navigate(`/history/${this.storeId}`) },
      { title: 'components.sidebar.coupons', icon: 'assets/icons/coupons.svg', action: () => this.navigate(`/coupons/${this.storeId}`) },
      { title: 'components.sidebar.help', icon: 'assets/icons/help.svg', action: () => this.navigate(`/faq/${this.storeId}`) },
    ]
    if (this.storeId) {
      this.links.unshift(
        { title: 'components.sidebar.store', icon: 'assets/icons/catalog.svg', action: () => this.navigate(`/catalog/${this.storeId}`) },
      )
    }
    else {
      this.links.unshift(
        { title: 'components.sidebar.home', icon: 'assets/icons/home.svg', action: () => this.navigate(`/home`) },
      )
    }
  }

  logOut() {
    this.analytics.logEvent('logout');
    this.auth.logout(this.storeId).catch(() => console.log("Sidebar error."));
  }

  navigate(route: string) {
    let analyticsData: { [key: string]: string } = { 'route': route.split("/")[1]}; // '/profile/STOREID' -> 'profile'
    this.analytics.logEvent('navigate_to', analyticsData);
    this.toggleSideBarForMe.emit();
    this.router.navigateByUrl(route);
  }
}
