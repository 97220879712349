import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';
import { CardInscriptionSelectionComponent } from './card-inscription-selection/card-inscription-selection.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ConfirmOpenComponent } from './confirm-open/confirm-open.component';
import { VerificationChargeInfoComponent } from './verification-charge-info/verification-charge-info.component';
import { HeaderComponent } from './header/header.component';
import { CurrencyClpPipe } from './pipes/currency-clp.pipe';
import { ActionButtonFooterComponent } from './action-button-footer/action-button-footer.component';
import { TermsComponent } from './terms/terms.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { EditableInputComponent } from './editable-input/editable-input.component';
import { GoogleButtonComponent } from './google-button/google-button.component';
import { PwaIncognitoModalComponent } from './pwa-incognito-modal/pwa-incognito-modal.component';
import { NumberToRomanPipe } from './pipes/number-to-roman.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [
    CardInscriptionSelectionComponent,
    FooterComponent,
    SidebarComponent,
    ConfirmOpenComponent,
    VerificationChargeInfoComponent,
    HeaderComponent,
    CurrencyClpPipe,
    ActionButtonFooterComponent,
    TermsComponent,
    OtpInputComponent,
    PhoneInputComponent,
    EditableInputComponent,
    GoogleButtonComponent,
    PwaIncognitoModalComponent,
    NumberToRomanPipe,
    SafeUrlPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    LottieModule,
    RouterModule,
    NgOtpInputModule,
    RecaptchaV3Module,
    MatRippleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatStepperModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MatRippleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatStepperModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    ReactiveFormsModule,
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxSpinnerModule,
    ZXingScannerModule, // Move this to specific module/component
    NgbModule,
    NgOtpInputModule,
    TranslateModule,
    LottieModule,
    FooterComponent,
    SidebarComponent,
    ActionButtonFooterComponent,
    OtpInputComponent,
    PhoneInputComponent,
    CurrencyClpPipe,
    EditableInputComponent,
    GoogleButtonComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
})
export class SharedModule { }
