<div class="flex flex-col items-center justify-center text-center h-full w-full">
  <ng-container *ngIf="!useBackButtonInCard">
    <div class="w-full h-1/6 flex items-center justify-start pl-5">
      <div class="flex items-center justify-center h-9 w-9 border-back-border border-4 border-solid bg-card-background rounded-md" (click)="goBack()">
        <mat-icon class="h-9 w-9 font-semibold" color="primary">chevron_left</mat-icon>
      </div>
    </div>
  </ng-container>
  <div class="bg-card-background outline outline-1 outline-neutral-300 w-11/12 min-w-xs max-w-lg min-h-96 shadow relative flex flex-col items-center text-center"
  [class.rounded-xl]="!isDespnsa"
  [class.justify-center]="!isDespnsa"
  [class.p-3]="!isDespnsa"
  [class.h-full]="isDespnsa"
  [class.rounded-tl-3xl]="isDespnsa"
  [class.rounded-tr-3xl]="isDespnsa"
  >
    <div class="p-4" *ngIf="showClientLogoAtLogin">
      <img src="assets/img/client_logo_dark.png" alt="Despnsa 24/7">
    </div>
    <h2 class="flex text-2xl text-center font-semibold justify-center m-2 text-card-title" [class.pb-4]="isDespnsa">{{otpTitle}}</h2>
    <span class="text-xs text-card-subtitle">{{ 'components.login_phone.ask_security_code' | translate: { userContact: userContact } }}</span>
    <div class="flex flex-col justify-center items-center p-2 w-full">
      <div class="w-full py-4">
        <ng-otp-input (onInputChange)="onOTPChange($event)" [config]="OTPConfig"></ng-otp-input>
        <input type="text"
          inputmode="numeric"
          autocomplete="one-time-code"
          pattern="\d{6}"
          [(ngModel)]="verificationCode"
          required
          hidden
          >
      </div>
      <button mat-raised-button class="w-full m-2" color="primary" [disabled]="!validOTP" (click)="submit()">Continuar</button>
    </div>
    <span class="text-xs">{{ 'components.login_phone.resend_code_question' | translate }}</span>
    <button *ngIf="resendDisabled" [disabled]="true" mat-button class="w-full mx-2 mt-2 mb-4">{{ 'components.login_phone.resend_code_timer' | translate: { resendTimer: resendTimer } }}</button>
    <div *ngIf="!resendDisabled" class="m-2 pb-2">
      <button mat-button class="w-full pb-2 font-medium" color="primary" (click)="resendCode('sms')">{{ 'components.login_phone.resend_code_sms' | translate }}</button>
      <button mat-button class="w-full font-medium" color="primary" (click)="resendCode('whatsapp')">{{ 'components.login_phone.resend_code_whatsapp' | translate }}</button>
    </div>
    <ng-container *ngIf="useBackButtonInCard">
      <mat-divider></mat-divider>
      <div class="flex flex-col justify-center items-center p-2 w-full">
        <button mat-icon-button class="w-full" (click)="goBack()" matTooltip="Volver">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
