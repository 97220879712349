import { Component, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-verification-charge-info',
  templateUrl: './verification-charge-info.component.html',
  styleUrls: ['./verification-charge-info.component.css']
})
export class VerificationChargeInfoComponent implements OnInit {

  // TODO: get verification amount from backend
  verificationAmount: number = 50
  constructor(public dialogRef: MatDialogRef<any>,) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
