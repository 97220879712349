import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  AmipassInitInput,
  APINoDataResponse,
  PaymentMethod,
  LoginUrlResponseData,
  User,
  APIDataResponse,
  AmipassInitResponseData,
  TwilioVerifyResponseData,
  IntegrationTransaction,
  FAQ,
  Store,
  UpdateUserResponseData,
  Coupons,
  TnCSection,
  CatalogData,
  Rating
} from '../interfaces';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private apiUrl = environment.apiUrl;
  subject = new BehaviorSubject(false);
  clientId: string = environment.clientId;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private sessionStorageService: SessionStorageService ) { }

  private async accessToken(): Promise<string|null> {
    const idTokenResult = await firstValueFrom(this.auth.getUsersIdTokenResult());
    return idTokenResult?.token;
  }

  private emulateResponse<T>(successful: boolean, data: T, message: string): APIDataResponse<T> {
    return {
      successful: successful,
      data: data,
      message: message
    }
  }

  private async getFromApi<T>(
    url: string,
    searchParams?: { [key: string]: any },
    requiresToken: boolean = true,
    sessionStorageKey?: string,
    sessionStorageExpirationInSeconds?: number
  ): Promise<APIDataResponse<T>> {
    const sessionStorageData: T | null = sessionStorageKey ? this.sessionStorageService.getItem(sessionStorageKey) : null;
    if (sessionStorageData !== null) {
      return this.emulateResponse<T>(true, sessionStorageData, 'Data found in session storage');
    }
    const headersObject = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    if (requiresToken) {
      let accessToken = await this.accessToken();
      if (!accessToken) {
        return this.emulateResponse(false, null, 'No auth token');
      }
      headersObject['Authorization'] = `Bearer ${accessToken}`;
    }
    const httpOptions = {
      headers: new HttpHeaders(headersObject),
    }
    const urlObject = new URL(url);
    const paramsObject = new URLSearchParams(searchParams ?? {});
    urlObject.search = paramsObject.toString();
    const response: APIDataResponse<T> = await firstValueFrom(this.http.get<APIDataResponse<T>>(urlObject.toString(), httpOptions));
    if (sessionStorageKey && response.successful) {
      this.sessionStorageService.setItem(sessionStorageKey, response.data, sessionStorageExpirationInSeconds);
    }
    return response;
    }

  async getCurrentUserData(updateUser: boolean=false): Promise<APIDataResponse<User>> {
    const storageKey: string = 'userData';
    if (updateUser) { this.sessionStorageService.removeItem(storageKey) }
    const url = `${this.apiUrl}/users/me`;
    const paramsObject = {
      legacy: false,
    }
    let response: APIDataResponse<User> = await this.getFromApi<User>(url, paramsObject, true, storageKey);
    if (response.successful) {
      // Filter user payment methods that are false in the current environment
      let newUserPaymentMethods = {};
      for (let userPaymentMethodKey in response.data.paymentMethods) {
        if (environment.paymentMethods[userPaymentMethodKey]) {
          if (userPaymentMethodKey === PaymentMethod.MERCADOPAGO) {
            if (Object.keys(response.data.paymentMethods[PaymentMethod.MERCADOPAGO]).includes(environment.marketplaceId)) {
              const paymentMethod = {}
              paymentMethod[environment.marketplaceId] = response.data.paymentMethods[PaymentMethod.MERCADOPAGO][environment.marketplaceId];
              newUserPaymentMethods[PaymentMethod.MERCADOPAGO] = paymentMethod;
            }
          }
          else {
            newUserPaymentMethods[userPaymentMethodKey] = response.data.paymentMethods[userPaymentMethodKey];
          }
        }
      }
      response.data.paymentMethods = newUserPaymentMethods;
      this.sessionStorageService.setItem('userData', response.data);
    }
    return response;
  }

  async sendTwilioRequest(to: string, captcha: string, channel: string = 'whatsapp'): Promise<APIDataResponse<{ sid: string }>> {
    const urlBase = `${this.apiUrl}/twilio/request`;
    const url = new URL(urlBase);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': captcha
      }),
    };
    let data = {
      to: to,
      channel: channel
    }
    return firstValueFrom(this.http.post<APIDataResponse<{ sid: string }>>(url.toString(), data, httpOptions));
  }

  async sendTwilioPhoneVerify(to: string, otp: string, create: boolean = false): Promise<APIDataResponse<TwilioVerifyResponseData>> {
    const urlBase = `${this.apiUrl}/twilio/phone/verify`;
    const url = new URL(urlBase);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
    };
    let data = {
      to: to,
      code: otp,
      create: create
    }
    return firstValueFrom(this.http.post<APIDataResponse<TwilioVerifyResponseData>>(url.toString(), data, httpOptions));
  }

  async sendTwilioEmailVerify(to: string, otp: string, create: boolean = false): Promise<APIDataResponse<TwilioVerifyResponseData>> {
    const urlBase = `${this.apiUrl}/twilio/email/verify`;
    const url = new URL(urlBase);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
    };
    let data = {
      to: to,
      code: otp,
      create: create
    }
    return firstValueFrom(this.http.post<APIDataResponse<TwilioVerifyResponseData>>(url.toString(), data, httpOptions));
  }

  // TODO: This POST should have data.
  async rateTransaction(transactionId: string, rating: number, comments: string = '', superTransactionId: string | null = null): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    if (!accessToken) {
      let response: APINoDataResponse = this.emulateResponse(false, null, 'No auth token');
      return response;
    }
    const urlBase = `${this.apiUrl}/transactions/${transactionId}/rate`;
    const url = new URL(urlBase);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    url.searchParams.append('rating', rating.toString());
    if (comments) {
      url.searchParams.append('comments', comments);
    }
    if (superTransactionId) {
      url.searchParams.append('superTransactionId', superTransactionId);
    }
    return firstValueFrom(this.http.post<APINoDataResponse>(url.toString(), {}, httpOptions));
  }

  async getUserPending(): Promise<APIDataResponse<IntegrationTransaction[]>> {
    const url = `${this.apiUrl}/users/pending`;
    return this.getFromApi<IntegrationTransaction[]>(url);
  }

  async getUserRejected(): Promise<APIDataResponse<IntegrationTransaction[]>> {
    const url = `${this.apiUrl}/users/rejected`;
    return this.getFromApi<IntegrationTransaction[]>(url);
  }

  async getUserTransactions(startAfter: number | null = null): Promise<APIDataResponse<IntegrationTransaction[]>> {
    const url = `${this.apiUrl}/transactions/me`;
    const paramsObject = {};
    if (startAfter) {
      paramsObject['startAfter'] = startAfter.toString();
    }
    return this.getFromApi<IntegrationTransaction[]>(url, paramsObject);
  }

  async getUserTransactionsCount(): Promise<APIDataResponse<number>> {
    const url = `${this.apiUrl}/transactions/me/count`;
    return this.getFromApi<number>(url);
  }

  async getStoreCatalog(storeId: string): Promise<APIDataResponse<CatalogData>> {
    const sessionStorageKey: string = `storeCatalog-${storeId}`
    const url = `${this.apiUrl}/stores/${storeId}/catalog`;
    return this.getFromApi<CatalogData>(url, null, false, sessionStorageKey);
  }

  async getStore(storeId: string): Promise<APIDataResponse<Store>> {
    const sessionStorageKey: string = `store-${storeId}`
    const url = `${this.apiUrl}/stores/${storeId}`;
    return this.getFromApi<Store>(url, null, false, sessionStorageKey);
  }

  async getStoreIdFromShortId(shortId: string): Promise<APIDataResponse<string>> {
    const url = `${this.apiUrl}/stores/short/${shortId}`;
    return this.getFromApi<string>(url, null, false);
  }

  async openStore(storeId: string, securityCode: string | null, tokenCode: string | null, deviceId: string | null): Promise<APIDataResponse< { transactionId: string}>> {
    let accessToken = await this.accessToken();
    if (!accessToken) {
      let response: APIDataResponse< { transactionId: string}> = this.emulateResponse(false, null, 'No auth token');
      return response;
    }
    const url = `${this.apiUrl}/stores/${storeId}/open`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    let data: { [key: string]: any } = {};
    if (securityCode) {
      data["encodedSecurityCode"] = btoa(securityCode);
    }
    if (tokenCode) {
      data["encodedTokenCode"] = btoa(tokenCode);
    }
    if (deviceId) {
      data["deviceId"] = deviceId;
    }
    return firstValueFrom(this.http.post<APIDataResponse< { transactionId: string}>>(url, data, httpOptions));
  }

  async cardInscription(coolerId: string | null): Promise<APIDataResponse<string>> {
    let accessToken = await this.accessToken();
    let data: { [key: string]: any } = {
      base_url: `${location.origin}/card-inscription`,
    };
    if (coolerId) {
      data["coolerId"] = coolerId;
    }
    const url = `${this.apiUrl}/users/transbank/card`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    return firstValueFrom(this.http.post<APIDataResponse<string>>(url, data, httpOptions));
  }

  async amipassInit(initData: AmipassInitInput): Promise<APIDataResponse<AmipassInitResponseData>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/recharges/amipass/init`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    return await firstValueFrom(this.http.post<APIDataResponse<AmipassInitResponseData>>(url, initData, httpOptions));
  }

  async amipassValidate(idPedido: string): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/recharges/amipass/validate/${idPedido}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    let response: APINoDataResponse = await firstValueFrom(this.http.patch<APINoDataResponse>(url, {}, httpOptions));
    return response;
  }

  async amipassDecline(idFracaso: string): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/recharges/amipass/decline/${idFracaso}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    let response: APINoDataResponse = await firstValueFrom(this.http.patch<APINoDataResponse>(url, {}, httpOptions));
    return response;
  }

  async confirmInscription(tbk_token: string, username: string): Promise<APIDataResponse<{tbk_response_code: number}>> {
    let accessToken = await this.accessToken();
    let data = {
      tbk_token: tbk_token,
      username: username
    }
    const url = `${this.apiUrl}/users/transbank/card`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    return firstValueFrom(this.http.patch<APIDataResponse<{tbk_response_code: number}>>(url, data, httpOptions));
  }

  async deleteInscription(): Promise<APIDataResponse<{tbk_response_code: number}>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/transbank/card`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    return firstValueFrom(this.http.delete<APIDataResponse<{tbk_response_code: number}>>(url, httpOptions));
  }

  async createUser(coolerId: string, email: string, name: string): Promise<APIDataResponse<User>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/create`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      coolerId: coolerId,
      email: email,
      name: name
    }
    return firstValueFrom(this.http.post<APIDataResponse<User>>(url, data, httpOptions));
  }

  async updateUser(id: string, email: string, name: string): Promise<APIDataResponse<UpdateUserResponseData>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/${id}/email`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      email: email,
      name: name,
    }
    return firstValueFrom(this.http.patch<APIDataResponse<UpdateUserResponseData>>(url, data, httpOptions));
  }

  async updateUserLegacy(id: string, email: string, name: string): Promise<APIDataResponse<UpdateUserResponseData>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/legacy`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      email: email,
      name: name,
    }
    return firstValueFrom(this.http.patch<APIDataResponse<UpdateUserResponseData>>(url, data, httpOptions));
  }

  async userExists(
    token: string,
    action: string,
    areaCode: string | null = null,
    number: string | null = null,
    email: string | null = null): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/exists`;
    let params = new HttpParams({
      fromObject: {
      }
    });
    if (areaCode && number) {
      params = params.append('areaCode', areaCode);
      params = params.append('number', number);
    }
    if (email) {
      params = params.append('email', email);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
        'X-Recaptcha-Token': token,
        'X-Recaptcha-Action': action
      }),
      params: params
    };
    return firstValueFrom(this.http.get<APINoDataResponse>(url, httpOptions));
  }

  async updateUserPhone(id: string, phoneNumber: string, areaCode: string): Promise<APIDataResponse<UpdateUserResponseData>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/${id}/phone`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      phoneNumber: phoneNumber,
      areaCode: areaCode,
      legacy: false
    }
    return firstValueFrom(this.http.patch<APIDataResponse<UpdateUserResponseData>>(url, data, httpOptions));
  }

  async createPhoneUser(coolerId: string, phoneNumber: string, areaCode: string): Promise<APIDataResponse<User>> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/phone/create`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      coolerId: coolerId,
      phoneNumber: phoneNumber,
      areaCode: areaCode,
      legacy: false
    }
    return firstValueFrom(this.http.post<APIDataResponse<User>>(url, data, httpOptions));
  }

  async addMercadopagoCard(
    token: any,
    issuer_id: any,
    payment_method_id: any,
    amount: any,
    installments: any,
    email: any,
    identificationType: any,
    identificationNumber: any,
    cardholderName: any,
    storeId: string | null = null,
    deviceId: string | null = null
  ): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/mercadopago/card`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      token: token,
      issuer_id: issuer_id,
      payment_method_id: payment_method_id,
      transaction_amount: Number(amount),
      installments: Number(installments),
      description: "Inscripción tarjeta de crédito.",
      payer: {
        email,
        identification: {
          type: identificationType,
          number: identificationNumber,
        },
      },
      clientId: this.clientId,
      cardholderName: cardholderName,
      storeId: storeId,
      deviceId: deviceId
    }
    return firstValueFrom(this.http.post<APINoDataResponse>(url, data, httpOptions));
  }

  async deleteMercadopagoCard(): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/mercadopago/card?clientId=${this.clientId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    return firstValueFrom(this.http.delete<APINoDataResponse>(url, httpOptions));
  }

  async getFaq(clientId: string | null = null): Promise<APIDataResponse<FAQ[]>> {
    const url = `${this.apiUrl}/faq`;
    let faqCache = this.sessionStorageService.getItem('faq');
    if (faqCache !== null) return this.emulateResponse(true, faqCache, 'Faq found in session storage');
    const paramsObject = {
      clientId: clientId,
    }
    return this.getFromApi<FAQ[]>(url, paramsObject, true, 'faq', 3600);
  }

  async usersHelp(message: string): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/help`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      message: message
    }
    return firstValueFrom(this.http.post<APINoDataResponse>(url, data, httpOptions));
  }

  async transactionsHelp(transactionId: string, message: string): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/transactions/${transactionId}/help`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    const data = {
      message: message
    }
    return firstValueFrom(this.http.post<APINoDataResponse>(url, data, httpOptions));
  }


  setBehavior(value: boolean) {
    this.subject.next(value)
  }

  async addCoupon(couponId: string): Promise<APINoDataResponse> {
    let accessToken = await this.accessToken();
    const url = `${this.apiUrl}/users/coupons/${couponId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`
      }),
    };
    let addCouponResponse = await firstValueFrom(this.http.post<APINoDataResponse>(url, {}, httpOptions));
    if (addCouponResponse.successful) {
      let coupons = this.sessionStorageService.getItem('coupons');
      if (coupons !== null) {
        coupons.push(couponId);
        this.sessionStorageService.setItem('coupons', coupons);
      }
    }
    return firstValueFrom(this.http.post<APINoDataResponse>(url, {}, httpOptions));
  }

  async getUsersCoupons(): Promise<APIDataResponse<Coupons>> {
    const url = `${this.apiUrl}/users/coupons`;
    return this.getFromApi<Coupons>(url, null, true, 'coupons');
  }

  async getTnC(): Promise<APIDataResponse<TnCSection[]>> {
    const url = `${this.apiUrl}/tnc`;
    return this.getFromApi<TnCSection[]>(url, null, false, 'tnc');
  }

  async getRating(transactionId: string): Promise<APIDataResponse<Rating>> {
    const url = `${this.apiUrl}/transactions/${transactionId}/rating`;
    return this.getFromApi<Rating>(url, null, true,`rating-${transactionId}`, 3600);
  }

  async getLoginUrl(): Promise<APIDataResponse<LoginUrlResponseData>> {
    const redirect_uri = window.origin + '/callback';
    const url = `${this.apiUrl}/oauth2/generate-url?redirect_uri=${redirect_uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    return firstValueFrom(this.http.get<APIDataResponse<LoginUrlResponseData>>(url, httpOptions));
  }

  async getCustomToken(token: string, codeVerifier: string): Promise<APIDataResponse<string>> {
    const url = `${this.apiUrl}/token/decode`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }),
    };
    return firstValueFrom(this.http.post<APIDataResponse<string>>(url, { token: token, code_verifier: codeVerifier }, httpOptions));
  }
}
