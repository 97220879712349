import { Component, Inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-pwa-incognito-modal',
  templateUrl: './pwa-incognito-modal.component.html',
  styleUrls: ['./pwa-incognito-modal.component.scss']
})
export class PwaIncognitoModalComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { promptEvent?: any },
    public platform: Platform
  ) { }

}
