<div class="min-h-full w-full p-6 flex flex-col items-center gap-3 bg-card-background">
  <!-- Title -->
  <div class="w-full max-w-xl flex items-center justify-center pt-10 pb-10">
    <div class="flex items-center w-0 overflow-visible h-full">
      <button mat-dialog-close mat-icon-button aria-label="Back">
        <mat-icon class="text-card-title">chevron_left</mat-icon>
      </button>
    </div>
    <div class="w-full flex items-center justify-center">
      <div class="text-center text-xl font-bold text-card-title">Términos y condiciones</div>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="sectionsLoaded ? dataLoaded : loading"/>
  <ng-template #dataLoaded>
    <div class="flex flex-col min-h-full w-full max-w-xl">
      <div *ngFor="let section of sections; index as i" class="pt-2">
        <h2 class="text-card-title text-left text-base">
          {{ i+1 | numberToRoman }}. {{ section.title | uppercase }}
        </h2>
        <ng-container *ngFor="let item of section.content">
          <ng-container [ngTemplateOutlet]="item.type === 'p' ? p : ol"/>
          <ng-template #p>
            <p class="text-card-default text-justify text-sm">
              {{ item.text }}
            </p>
          </ng-template>
          <ng-template #ol>
            <ol class="text-card-default text-sm">
              <li *ngFor="let li of item.items">
                {{ li }}
              </li>
            </ol>
          </ng-template>
        </ng-container>
      </div>
      <button mat-raised-button mat-dialog-close color="primary" class="mt-3">Cerrar</button>
    </div>
  </ng-template>
  <ng-template #loading>
    <div class="flex h-full justify-center items-center">
      <mat-spinner/>
    </div>
  </ng-template>
</div>