import { Injectable } from '@angular/core';

interface SessionStorageServiceI {
  setItem(key: string, value: any, expiration: number): void;
  getItem(key: string): any;
  removeItem(key: string): void;
  clear(): void;
}

interface SessionStorageItemI {
  value: any;
  expiration: number;
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements SessionStorageServiceI {
  private defaultExpiration = 300; // 5 minutes

  constructor() {
    window.addEventListener('beforeunload', () => { sessionStorage.clear(); });
  }

  private isExpired(key: string): boolean {
    const item: SessionStorageItemI | null = JSON.parse(sessionStorage.getItem(key));
    if (!item) return true;
    return Date.now() > item.expiration;
  }

  setItem(key: string, value: any, expirationInSeconds: number=this.defaultExpiration): void {
    const expirationInMiliseconds = expirationInSeconds * 1000;
    const item: SessionStorageItemI = {
      value: value,
      expiration: Date.now() + expirationInMiliseconds
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  getItem(key: string) {
    if (this.isExpired(key)) {
      this.removeItem(key);
      return null;
    }
    const item: SessionStorageItemI | null = JSON.parse(sessionStorage.getItem(key));
    return item?.value;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }

  clearUser() {
    this.removeItem('userData');
    this.removeItem('coupons');
  }
}
