<div class="flex flex-col items-center justify-center h-full w-full overflow-auto bg-confirm-open-background confirm-open-container">
  <div class="flex flex-col items-center bg-confirm-open-inner-background max-w-80 p-3 confirm-open-inner-container">
    <ng-container *ngIf="requireSecurityCode">
      <mat-stepper #stepper>
        <mat-step>
          <ng-container *ngTemplateOutlet="securityCodeTemplate"/>
          <div class="flex flex-col flex-wrap justify-center w-full gap-2 mt-2">
            <button mat-raised-button matStepperNext color="primary" [disabled]="securityCode?.length != 3">Continuar</button>
            <button mat-raised-button mat-dialog-close (click)="close()">{{ 'components.confirm_open.back_button' | translate }}</button>
          </div>
        </mat-step>
        <mat-step>
          <ng-container *ngTemplateOutlet="openTemplate"/>
        </mat-step>
      </mat-stepper>
    </ng-container>
    <ng-container *ngIf="!requireSecurityCode">
      <ng-container *ngTemplateOutlet="openTemplate"/>
    </ng-container>
  </div>
</div>

<ng-template #openTemplate>
  <ng-container *ngIf="store?.storeType !='COFFEE'">
    <h2 class="text-confirm-open-title text-xl font-bold text-center mt-3 mb-5 mx-4">{{ 'components.confirm_open.title' | translate }}</h2>
    <div class="flex justify-center items-center p-2">
      <ng-lottie [options]="{ path: '/assets/animations/open.json', loop: true }"/>
    </div>
  </ng-container>
  <ng-container *ngIf="store?.storeType =='COFFEE'">
    <h2 class="text-confirm-open-title text-xl font-bold text-center mt-3 mb-5 mx-4">{{ 'components.confirm_open.coffee_title' | translate }}</h2>
    <div class="flex justify-center items-center p-2">
      <ng-lottie [options]="{ path: '/assets/animations/connect.json', loop: true }"/>
    </div>
  </ng-container>
  <div class="flex flex-col flex-wrap justify-center w-full gap-2 mt-2">
    <span *ngIf="shouldValidate" class="text-sm text-neutral-800 text-center mt-2">
      Realizaremos un cobro de prevalidación a tu método de pago inscrito por <b>{{userValidationAmount}}</b>.
      Si retiras productos por un precio menor este monto será devuelto.
      Si retiras productos por un precio mayor se realizará un cobro adicional por la diferencia.
    </span>
    <button mat-raised-button color="primary" (click)="confirmOpen()">{{ 'components.confirm_open.confirm_button' | translate | uppercase }}</button>
    <button mat-raised-button mat-dialog-close (click)="close()">{{ 'components.confirm_open.back_button' | translate | uppercase }}</button>
  </div>
</ng-template>

<ng-template #securityCodeTemplate>
  <div class="flex flex-col justify-center items-center">
    <div class="flex justify-center items-center content-center w-3/4 py-3 px-0">
      <div class="shadow-2xl rounded-2xl w-full">
        <div class="antialiased backface-hidden w-full max-w-sm text-neutral-600">
          <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 750 471" style="enable-background:new 0 0 750 471;" xml:space="preserve">
            <g id="Back">
              <g id="Page-1_2_">
                <g id="amex_2_">
                  <path id="Rectangle-1_2_" class="fill-shopcard-background" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
              C0,17.9,17.9,0,40,0z" />
                </g>
              </g>
              <rect y="61.6" class="fill-neutral-950" width="750" height="78" />
              <g>
                <path class="fill-neutral-50" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
            C707.1,246.4,704.4,249.1,701.1,249.1z" />
                <rect x="42.9" y="198.6" class="fill-neutral-300" width="664.1" height="10.5" />
                <rect x="42.9" y="224.5" class="fill-neutral-300" width="664.1" height="10.5" />
                <path class="fill-neutral-400"
                  d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
              </g>
              <text transform="matrix(1 0 0 1 621 227)" id="svgsecurity"
                class="font-mono font-normal">{{hiddenSecurityCode}}</text>
              <g class="opacity-60">
                <text transform="matrix(1 0 0 1 618 285)" class="font-mono font-normal fill-white text-3xl">CVV</text>
              </g>
              <rect x="58.1" y="378.6" class="fill-neutral-200" width="375.5" height="13.5" />
              <rect x="58.1" y="405.6" class="fill-neutral-200" width="421.7" height="13.5" />
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="flex justify-center w-full pb-5">
      <mat-form-field class="w-4/5 my-2" appearance="outline">
        <mat-label>Código de seguridad</mat-label>
        <input type="password" maxlength="4" matInput [(ngModel)]="securityCode" (input)="onSecurityCodeChange($event)">
        <mat-hint class="text-neutral-800" align="start">Ingresa el código de seguridad para validar tu
          identidad.</mat-hint>
      </mat-form-field>
    </div>
  </div>
</ng-template>
