import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
  host: {
    class: 'p-2'
  }
})
export class GoogleButtonComponent {
  @Input() action: () => void = () => {};
  @Input() text: string = 'Continuar con Google';
}
