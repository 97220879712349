import { Component, OnInit } from '@angular/core';
import { APIDataResponse, TnCSection } from 'src/app/interfaces';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  sections: TnCSection[] = [];
  sectionsLoaded: boolean = false;

  constructor(
    private http: HttpService
  ) { }

  ngOnInit() {
    this.http.getTnC()
    .then((getTncResponse: APIDataResponse<TnCSection[]>) => {
      this.sections = getTncResponse.data;
      this.sectionsLoaded = true;
    })
  }
}
