import { RouterModule, Routes } from '@angular/router';
import { privateGuard } from './guards/private.guard';

const APP_ROUTES: Routes = [
  // PRIVATE ROUTES
  { path: '',
  loadChildren: () => import('./private/private.module').then(m => m.PrivateModule),
  canMatch: [privateGuard],
  },
  // PUBLIC ROUTES
  { path: '',
  loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
