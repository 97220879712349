<div class="h-full flex flex-col justify-center p-3 max-w-xl">
  <h2 class="text-catalog-title text-base font-semibold">
    <svg *ngIf="!platform.IOS" class="mr-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M17.06 13c-1.86 0-3.42 1.33-3.82 3.1c-.95-.41-1.82-.3-2.48-.01C10.35 14.31 8.79 13 6.94 13C4.77 13 3 14.79 3 17s1.77 4 3.94 4c2.06 0 3.74-1.62 3.9-3.68c.34-.24 1.23-.69 2.32.02c.18 2.05 1.84 3.66 3.9 3.66c2.17 0 3.94-1.79 3.94-4s-1.77-4-3.94-4M6.94 19.86c-1.56 0-2.81-1.28-2.81-2.86s1.26-2.86 2.81-2.86c1.56 0 2.81 1.28 2.81 2.86s-1.25 2.86-2.81 2.86m10.12 0c-1.56 0-2.81-1.28-2.81-2.86s1.25-2.86 2.81-2.86s2.82 1.28 2.82 2.86s-1.27 2.86-2.82 2.86M22 10.5H2V12h20zm-6.47-7.87c-.22-.49-.78-.75-1.31-.58L12 2.79l-2.23-.74l-.05-.01c-.53-.15-1.09.13-1.29.64L6 9h12l-2.44-6.32z"/></svg>
    {{
    (platform.ANDROID ? 'components.pwa_incognito_modal.android.title' :
    platform.IOS ? 'components.pwa_incognito_modal.ios.title':
    'components.pwa_incognito_modal.desktop.title') | translate
    }}
  </h2>
  <div>
    <h3 class="text-catalog-subtitle font-semibold text-sm">
      {{ (platform.ANDROID ? 'components.pwa_incognito_modal.android.subtitle' :
      platform.IOS ? 'components.pwa_incognito_modal.ios.subtitle':
      'components.pwa_incognito_modal.desktop.subtitle') | translate
      }}
    </h3>
    <div *ngIf="platform.IOS" class="py-3 flex justify-center text-center">
      <img class="w-72" src="https://storage.googleapis.com/smart-stores-misc/ios_footbar.jpeg">
    </div>
  </div>
</div>