import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyClp'
})
export class CurrencyClpPipe implements PipeTransform {

  transform(value: number): string {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  }

}
