import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultiTransactionStateManagerService {

  previousTransactionResults: any;
  private _autoConfirmEnabled: boolean = false;


  enableAutoconfirm(transactionResults: any): void {
    this.previousTransactionResults = transactionResults;
    this._autoConfirmEnabled = true;
  }

  disableAutoconfirm(): void {
    this.previousTransactionResults = null;
    this._autoConfirmEnabled = false;
  }

  get autoConfirmEnabled(): boolean {
    return this._autoConfirmEnabled;
  }

}
