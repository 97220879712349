<h1 mat-dialog-title>Recargar saldo</h1>
<mat-dialog-content>
  <div class="flex flex-col h-full w-full">
    <p>El monto mínimo a cargar es de <b>${{minimumRechargeAmount}}</b>.</p>
    <p><b>IMPORTANTE</b>: No cierres la ventana pestaña después de realizar la compra en Amipass para poder ser redirigido y validar la recarga.</p>
    <mat-form-field class="mt-3" appearance="outline">
      <mat-label>Monto a cargar</mat-label>
      <input matInput type="number" max="100000" [(ngModel)]="amount">
    </mat-form-field>
    <button mat-raised-button color="primary"
      [disabled]="amount < minimumRechargeAmount || amount > maximumRechargeAmount"
      (click)="onSend()">
        Recargar
    </button>
  </div>
</mat-dialog-content>
