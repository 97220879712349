import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountryCode } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import { PhoneEvent } from 'src/app/interfaces';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})
export class PhoneInputComponent implements OnChanges{

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  countryCodes: CountryCode[] = environment.countryCodes;
  selectedCountryCode: CountryCode = environment.countryCodes[0];
  @Input() error?: boolean;
  @Output() errorChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onPhoneNumberCompleted: EventEmitter<PhoneEvent> = new EventEmitter<PhoneEvent>();

  hasError: ValidatorFn = (control: FormControl) => {
    return (this.error) ? { 'hasError': true } : null;
  }

  phoneControl: FormControl = new FormControl(null, [Validators.required, Validators.min(100000000), Validators.max(999999999), this.hasError])

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error && changes.error.currentValue) {
      this.phoneControl.setErrors({hasError: true});
    }
  }

  onPhoneNumberTouched() {
    this.errorChange.emit(false);
  }

  sendPhoneNumber() {
    let completePhoneNumber = `${this.selectedCountryCode.dial_code}${this.phoneControl.value}`
    if (this.selectedCountryCode.dial_code == environment.countryCodes[0].dial_code &&
        this.phoneControl.value.toString().length != 9) {
          this.openSnackBar(`El número de teléfono debe tener 9 dígitos. El número que ingresaste tiene ${this.phoneControl.value.toString().length} dígitos.`)
          return;
    }
    this.onPhoneNumberCompleted.emit({
      phoneNumber: this.phoneControl.value,
      dialCode: this.selectedCountryCode.dial_code,
      completePhoneNumber
    });
  }

  openSnackBar(message: string) {
    let action = "Cerrar"
    this._snackBar.open(message, action, {
      duration: 10000,
      panelClass : "custom-snackbar"
    });
  }

  onPhoneNumberChange(number: number) {
    if (number && number.toString().length > 13) {
      this.phoneControl.setValue(Number(number.toString().slice(0, 13)));
    }
  }

}
