import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { BrowserType, DeviceType, PWAContext } from '../interfaces';
import { timer } from 'rxjs';
import { PwaIncognitoModalComponent } from '../shared/pwa-incognito-modal/pwa-incognito-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
declare function detectIncognito(): Promise<any>;
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  public context: PWAContext;
  private promptEvent: any;

  constructor(
    private bottomSheet: MatBottomSheet,
    public platform: Platform) { }

  public initPWA() {
    this.eventListener();
    this.getContext();
  }

  public installPwa(): void {
    this.promptEvent?.prompt();
  }

  public canInstallPwa(): boolean {
    return !!this.promptEvent;
  }

  public isPrivateMode(): Promise<boolean> {
    return detectIncognito()
      .then((result) => {
        return result.isPrivate;
      })
      .catch((error) => {
        console.error("Error al detectar el modo incógnito:", error);
        return false;
      });
  }

  public askToCloseIncognito(time: number = 1500) {
    timer(time).subscribe(() => {
      this.bottomSheet.open(PwaIncognitoModalComponent,
        { data: { promptEvent: this.promptEvent }
      });
    })
  }

  private eventListener(): void {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.promptEvent = event;
    });
    window.addEventListener("appinstalled", () => {
      this.promptEvent = null;
      this.context.isPWA = true;
    });
  }

  private getContext(): void {
    this.context = {
      isPWA: window.matchMedia('(display-mode: standalone)').matches,
      device: this.getDevice(),
      browser: this.getBrowser()
    };
  }

  getDevice(): PWAContext["device"] {
    if (this.platform.ANDROID) return DeviceType.ANDROID
    if (this.platform.IOS) return DeviceType.IOS
    return DeviceType.DESKTOP
  }

  private getBrowser(): PWAContext["browser"] {
    if (this.platform.BLINK) return BrowserType.CHROME
    if (this.platform.FIREFOX) return BrowserType.FIREFOX
    if (this.platform.SAFARI) return BrowserType.SAFARI
    if (this.platform.EDGE) return BrowserType.EDGE
    return BrowserType.OTHER
  }

}

export const initPWA = (pwaService: PwaService) => () => pwaService.initPWA();
