import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthProvider, PhoneAuthProvider, RecaptchaVerifier, getAuth } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SessionStorageService } from './session-storage.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  reCaptchaVerifier: RecaptchaVerifier | undefined = undefined;

  constructor(
    private auth: AngularFireAuth,
    public router: Router,
    private sessionStorage: SessionStorageService,
  ) { }

  loginInApp(user: firebase.default.User){
    return user.getIdToken(false);
  }

  loginWithEmailAndPassword(email: string, password: string){
    try {
      return this.auth.signInWithEmailAndPassword(email, password);
    }
    catch(err) {
      console.log("Login error: ", err)
      return null;
    }
  }

  getRecaptchaVerifier() {
    let authInstance = getAuth();
    if (this.reCaptchaVerifier === undefined) {
      this.reCaptchaVerifier = new RecaptchaVerifier('sign-in-button',
      {'size': 'invisible'},
      authInstance);
    }
    return this.reCaptchaVerifier;
  }

  getOTP(phoneNumber: string) {
    let reCaptchaVerifier = this.getRecaptchaVerifier();
    return this.auth.signInWithPhoneNumber(phoneNumber, reCaptchaVerifier)
  }

  loginWithPhone(verificationId: string, verificationCode: string) {
    var credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
    return this.auth.signInWithCredential(credentials);
  }

  addPhoneToUser(verificationId: string, verificationCode: string) {
    var credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
    return this.auth.currentUser.then(user => user!.updatePhoneNumber(credentials));
  }

  loginWithCustomToken(token: string) {
    return this.auth.signInWithCustomToken(token);
  }

  registerWithEmailAndPassword(email: string, password: string) {
    try {
      return this.auth.createUserWithEmailAndPassword(email, password);
    }
    catch(err) {
      console.log("Login error: ", err)
      return null;
    }
  }

  loginWithGoogle(){
    return this.auth.signInWithPopup(new GoogleAuthProvider());
  }


  sendEmailVerification(){
    return this.auth.user.subscribe(u => u!.sendEmailVerification());
  }


  updatePassword(email: string, password: string, newPassword: string){
    return this.loginWithEmailAndPassword(email, password)!.then(() => this.auth.user.subscribe(u => u!.updatePassword(newPassword)));
  }


  recoverPassword(email: string){
    return this.auth.sendPasswordResetEmail(email);
  }


  getUser() {
    return this.auth.user;
  }

  async logout(storeId: string = ''){
    this.auth.signOut().then(() => {
      this.sessionStorage.clearUser();
      this.router.navigateByUrl(`/login/${storeId}`);
    })
  }

  getUsersIdTokenResult() {
    return this.auth.idTokenResult;
  }

}
