import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss'],
})
export class EditableInputComponent<T> implements OnChanges {

  @Input() value: T;
  @Input() includeEditButton: boolean = true;
  @Input() externalAdd: () => void;

  @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();
  innerValue: T;
  isEditing: boolean = false;

  ngOnChanges() {
    this.innerValue = this.value;
  }

  private _saveNewValue() {
    if (typeof this.innerValue === 'string' && typeof this.value === 'string') {
      if (this.innerValue.trim() !== this.value.trim()) {
        this.valueChange.emit(this.innerValue.trim() as T);
      }
    }
    else {
      this.valueChange.emit(this.innerValue);
    }
  }

  onButtonClick() {
    if (this.isEditing) {
      this._saveNewValue();
    }
    this.isEditing = !this.isEditing;
  }

  setValue(value: T) {
    this.value = value;
    this.innerValue = value;
  }
}
