import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, isDevMode, LOCALE_ID } from '@angular/core';
import { ExtraOptions, PreloadAllModules } from '@angular/router';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import {HttpClient } from '@angular/common/http';
//module from firebase
import { AngularFireModule } from '@angular/fire/compat';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAnalyticsModule, CONFIG } from "@angular/fire/compat/analytics";
// Local imports
import { environment } from '../environments/environment';
import { APP_ROUTING } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
// Function to initialize the PWA service
import { PwaService, initPWA } from './services/pwa.service';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
registerLocaleData(localeEs, 'es');

export function playerFactory() {
  return import('lottie-web');
}

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    APP_ROUTING,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http:HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); },
        deps: [HttpClient]
      }
    }),
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    DatePipe,
    ScreenTrackingService,
    UserTrackingService,
    { provide: CONFIG, useValue: {
      allow_ad_personalization_signals: false,
      anonymize_ip: true
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initPWA,
      deps: [PwaService],
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
