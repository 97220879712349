import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { APIDataResponse, LoginUrlResponseData } from '../interfaces';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoginReiteService {

  codeVerifier: string = '';
  hashedCodeVerifier: string = '';
  state: string = '';
  scope: string = '';
  popup: Window | null = null;

  constructor(
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private http: HttpService,
  ) {
    if (!environment.useOauth) return;
    window.addEventListener('message', (event) => {
      if (event.origin === window.location.origin && event.isTrusted && event.data) {
        if (typeof event.data === "string" && event.data.includes(`${window.location.origin}/callback`) ) {
          this.closePopup();
          const urlParams = new URLSearchParams(event.data);
          const token = urlParams.get('token');
          if (!token) return;
          this.spinner.show();
          this.http.getCustomToken(token, this.codeVerifier).then((response: APIDataResponse<string>) => {
            this.auth.loginWithCustomToken(response.data);
          })
          .finally(() => this.spinner.hide());
        }
      }
    });
  }

  openPopup(): void {
    if (!environment.useOauth) return;
    const width = window.screen.width;
    const height = window.screen.height;
    this.popup = window.open('', 'Reite Login', `width=${width}, height=${height}`);
    this.http.getLoginUrl().then((response: APIDataResponse<LoginUrlResponseData>) => {
      if (this.popup) {
        this.codeVerifier = response.data.code_verifier;
        this.hashedCodeVerifier = response.data.hashed_code_verifier;
        this.state = response.data.state;
        this.scope = response.data.scope;
        this.popup.location.href = response.data.url;
      }
    });
  }

  closePopup(): void {
    if (!environment.useOauth) return;
    this.popup?.close();
    this.popup = null;
  }
}
