import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerLogoTheme = environment.footerLogoTheme;
  logoUrl!: string;

  constructor() { }

  ngOnInit(): void {
    this.logoUrl = this.footerLogoTheme === 'light' ? 'assets/img/reite_logo_white.png' : 'assets/img/reite_logo_dark.png';
  }
}
