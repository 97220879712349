import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { RouteTrackerService } from './route-tracker.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private analytics: AngularFireAnalytics,
              private routeTracker: RouteTrackerService) {
  }

  logEvent(name: string, options: any = null){
    let storeId = this.routeTracker.getStoreIdSnapshot();
    let finalOptions = options? {...options } : {};
    if (storeId) {
      finalOptions['store_id'] = storeId;
    }
    if (environment.clientId) {
      finalOptions['client_id'] = environment.clientId;
    }
    this.analytics.logEvent(name, finalOptions);
  }

  setUserId(userId: string): void{
    this.analytics.setUserId(userId);
  }
}
