import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { APIDataResponse, User } from 'src/app/interfaces';
import { HttpService } from 'src/app/services/http.service';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TransbankService {

  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpService,
    ) { }

  async cardInscription(storeId: string | null = null) {
    this.spinner.show();
    await this.http.cardInscription(storeId)
    .then((inscriptionResponse: APIDataResponse<string>) => {
      this.spinner.hide();
      window.open(inscriptionResponse.data, "_self");
    })
    .catch((error: HttpErrorResponse) => {
      this.spinner.hide();
      console.log(error.error);
    })
  }
}
