<mat-toolbar color="primary">
    <button (click)="toggleSideBar()" mat-icon-button class="menu" aria-label="Menu">
        <mat-icon>menu</mat-icon>
    </button>
    <span class="text-contrast" *ngIf="!production">DEV {{ clientName }}</span>
    <span class="flex-auto"></span>
    <span>
      <div class="flex items-start justify-center h-12 px-2 py-1">
        <img class="h-full w-full max-w-32 object-contain" src='assets/img/toolbar_logo.png'>
      </div>
    </span>
</mat-toolbar>
