<mat-sidenav-container [autofocus]="false">
  <div class="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden items-center">
    <h4 class="text-title-color text-2xl font-bold text-center w-full max-w-xl mb-4" [innerHTML]="'components.sidebar.description' | translate"></h4>
    <ng-container *ngIf="verified">
      <a mat-raised-button (click)="navigate('/pleasescan/'+ storeId)" routerLinkActive="list-item-active" class="sidebar-button highlight pt-4 pb-4 mb-2">
        <div class="w-full flex pl-2 pr-2 gap-2 items-center">
          <div class="icon-wrapper flex justify-center items-center mr-2">
            <img src="assets/icons/scanner.svg" [alt]="'components.sidebar.scan' | translate">
          </div>
          <span class="text-sidebar-text-color">{{ 'components.sidebar.scan' | translate }}</span>
        </div>
      </a>
      <ng-container *ngFor="let link of links">
        <a mat-raised-button (click)="link.action()" routerLinkActive="list-item-active" class="sidebar-button pt-4 pb-4 mb-2">
          <div class="w-full flex pl-2 pr-2 gap-2 items-center">
            <div class="icon-wrapper flex justify-center items-center mr-2">
              <img [src]="link.icon" [alt]="link.title | translate">
            </div>
            <span class="text-sidebar-text-color">{{ link.title | translate }}</span>
          </div>
        </a>
      </ng-container>
    </ng-container>
    <a mat-raised-button (click)="logOut()" routerLinkActive="list-item-active" class="sidebar-button pt-4 pb-4 mb-2">
      <div class="w-full flex items-center pl-2 pr-2 gap-2">
        <div class="logout-wrapper flex justify-center items-center mr-2">
          <img src="assets/icons/logout.svg" alt="Log Out">
        </div>
        <span class="text-red-500">{{ 'components.sidebar.logout' | translate }}</span>
      </div>
    </a>
  </div>
</mat-sidenav-container>
