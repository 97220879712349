import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  devicesCollection: string = "Devices";
  coolersCollection: string = "Coolers";
  usersCollection: string = "Users";
  integrationTransactionsCollection: string = "IntegrationTransactions";
  validatedTransactionsCollection: string = "ValidatedTransactions";
  productsCollection: string = "Products";
  faqCollection: string = "FAQ";
  user: any;
  currentDevice: any;
  constructor(private firestore: AngularFirestore) {}

  transactionDocumentReference(transactionId: string, superTransactionId: string | null = null) {
    if (superTransactionId) {
      return this.firestore.collection(this.integrationTransactionsCollection).doc(superTransactionId).collection(this.integrationTransactionsCollection).doc(transactionId);
    }
    else {
      return this.firestore.collection(this.integrationTransactionsCollection).doc(transactionId);
    }
  }

  createUser(user: firebase.default.User, coolerId: string = ""): Promise<void> {
    return this.firestore.collection(this.usersCollection).doc(user.uid).set({
      active: true,
      coolerId: coolerId,
      id: user.uid,
      email: user.email,
      name: user.displayName,
      paymentMethods: {}
    });
  }

  getFaq() {
    return this.firestore.collection(this.faqCollection, ref => ref).get();
  }
}
