import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User, Store } from 'src/app/interfaces';
import { VerificationChargeInfoComponent } from '../verification-charge-info/verification-charge-info.component';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';

export interface ConfirmOpenData {
  requireSecurityCode: boolean;
  securityCode: string;
  user: User;
  store: Store;
}

@Component({
  selector: 'app-confirm-open',
  templateUrl: './confirm-open.component.html',
  styleUrls: ['./confirm-open.component.scss']
})
export class ConfirmOpenComponent implements OnInit {

  requireSecurityCode: boolean;
  securityCode: string;
  hiddenSecurityCode: string = "";
  user: User;
  store: Store;
  shouldValidate: boolean
  userValidationAmount: string;

  constructor(
    private utils: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOpenData) {
      this.user = data.user;
      this.store = data.store;
      this.requireSecurityCode = data.requireSecurityCode;
      this.securityCode = data.securityCode;
      if (this.securityCode) {
        this.hiddenSecurityCode = "*".repeat(this.securityCode.length);
      }
      if (this.store.validationAmount) {
        this.userValidationAmount = this.utils.parsePrice(this.store.validationAmount);
      }
      else {
        this.userValidationAmount = this.utils.parsePrice(environment.USER_VALIDATION_AMOUNT);
      }
      let lastValidationTimestamp = this.user?.lastValidationTimestamp || 0;
      if (this.user.transactionsCount <= environment.USER_VALIDATION_COUNT && lastValidationTimestamp + environment.USER_VALIDATION_TIME <= new Date().getTime() / 1000) {
        this.shouldValidate = true;
      }
      else {
        this.shouldValidate = false;
      }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(VerificationChargeInfoComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log("closed")
    });
  }

  onSecurityCodeChange(event: any) {
    this.hiddenSecurityCode = "*".repeat(this.securityCode.length);
  }

  close() {
    this.dialogRef.close();
  }

  confirmOpen() {
    this.dialogRef.close({securityCode: this.securityCode});
  }

}
