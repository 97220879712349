import { CountryCode } from "src/app/interfaces";

export const environment = {
  // Client
  clientId: '9rC1BqE38spjg88Rl0s3',
  clientName: 'Default',
  // Production
  production: false,
  // apiUrl: 'http://localhost:8181',
  apiUrl: 'https://smart-coolers-web-api-test-akcgfrvrqa-uc.a.run.app',
  // Payment
  marketplaceId: 'default',
  mp_public_key: 'APP_USR-5f70d2f9-7f6c-4034-9c77-a8870bfc18e4',
  balanceRechargeActive: false,
  minimumValidateAmount: 2500,
  minimumRechargeAmount: 2500,
  maximumRechargeAmount: 250000,
  USER_VALIDATION_AMOUNT: 9876,
  USER_VALIDATION_TIME: 86400,
  USER_VALIDATION_COUNT: 10,
  showBalance: true,
  paymentMethods: {
    transbank: true,
    mercadopago: false,
    mercadopago2: true,
    saldo_aramark: false,
    saldo_sodexo: true,
    saldo_sodexo2: false,
    saldo_masfrutas: false,
    saldo_cnv: false,
    saldo_eurovending: true,
    saldo_reite: false,
  },
  login: {
    mustVerify: false,
    mustFinishInscription: true,
    mustFinishPhone: false,
    },
  legacyAccountTimestamp: new Date('2024-12-17').getTime() / 1000,
  recaptcha: {
    siteKey: '6LfZUlkpAAAAAGzt_h0MHhf2sOPqojCpuW5DNrCZ',
    },
  firebase: {
    apiKey: "AIzaSyBmTshKmqfuUsKBq3cnJXp9WZN9R07JVj0",
    authDomain: "smart-coolers-dev.firebaseapp.com",
    projectId: "smart-coolers-dev",
    storageBucket: "smart-coolers-dev.appspot.com",
    messagingSenderId: "1046712374329",
    appId: "1:1046712374329:web:df191cda588005fd6269e8",
    measurementId: "G-33BQQETG9Z"
    },
  countryCodes: [
    {"name": "Chile", "dial_code": "+56", "code": "CL", "src": "assets/img/flag-chile.png"},
    {"name": "Alemania", "dial_code": "+49", "code": "DE", "src": "assets/img/flag-germany.png"},
    {"name": "Argentina", "dial_code": "+54", "code": "AR", "src": "assets/img/flag-argentina.png"},
    {"name": "Bolivia", "dial_code": "+591", "code": "BO", "src": "assets/img/flag-bolivia.png"},
    {"name": "Brasil", "dial_code": "+55", "code": "BR", "src": "assets/img/flag-brazil.png"},
    {"name": "Canadá", "dial_code": "+1", "code": "CA", "src": "assets/img/flag-canada.png"},
    {"name": "Colombia", "dial_code": "+57", "code": "CO", "src": "assets/img/flag-colombia.png"},
    {"name": "Ecuador", "dial_code": "+593", "code": "EC", "src": "assets/img/flag-ecuador.png"},
    {"name": "España", "dial_code": "+34", "code": "ES", "src": "assets/img/flag-spain.png"},
    {"name": "Estados Unidos", "dial_code": "+1", "code": "US", "src": "assets/img/flag-united-states.png"},
    {"name": "Francia", "dial_code": "+33", "code": "FR", "src": "assets/img/flag-france.png"},
    {"name": "Italia", "dial_code": "+39", "code": "IT", "src": "assets/img/flag-italy.png"},
    {"name": "México", "dial_code": "+52", "code": "MX", "src": "assets/img/flag-mexico.png"},
    {"name": "Paraguay", "dial_code": "+595", "code": "PY", "src": "assets/img/flag-paraguay.png"},
    {"name": "Perú", "dial_code": "+51", "code": "PE", "src": "assets/img/flag-peru.png"},
    {"name": "Reino Unido", "dial_code": "+44", "code": "GB", "src": "assets/img/flag-united-kingdom.png"},
    {"name": "Uruguay", "dial_code": "+598", "code": "UY", "src": "assets/img/flag-uruguay.png"},
    {"name": "Venezuela", "dial_code": "+58", "code": "VE", "src": "assets/img/flag-venezuela.png"},
  ] as CountryCode[],
  // Deep customization
  isDespnsa: false, // Special customization for this client. May refactor to a more general solution in the future.
  themeColor: "#f6f6f9",
  footerLogoTheme: 'light',
  isDarkTheme: false,
  showToolbarUnlogged: true,
  showLogoOnToolbar: true,
  showPwaOnToolbar: false,
  showFooterUnlogged: true,
  showFooterLogged: true,
  showClientLogoAtLogin: false,
  displayInfoInLogin: false,
  useBackButtonInCard: true,
  useOauth: false,
  // Faq and help
  faq: {
    contactInApp: true,
    goToContact: () => {},
    suggestInApp: true,
    goToSuggest: () => {},
  }
};
