import { Injectable } from '@angular/core';
import { AmipassInitInput, AmipassInitResponseData, APIDataResponse } from '../interfaces';
import { HttpService } from './http.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AmipassService {
  constructor(
    private http: HttpService
  ) {}

  amipassInit(amount: number, storeId: string, spinner?: any, snackbar?: any) {
    spinner?.show();
    let amipassInitInput: AmipassInitInput = {
      amount: amount,
      urlExito: `${window.location.origin}/amipass/validate${storeId ? `?storeId=${storeId}` : ''}`,
      urlFracaso: `${window.location.origin}/amipass/decline${storeId ? `?storeId=${storeId}` : ''}`,
    }
    this.http.amipassInit(amipassInitInput)
    .then(
      (response: APIDataResponse<AmipassInitResponseData>) => {
        if (!response.successful) {
          console.log(response.message);
          snackbar?.open("Algo salió mal al iniciar la transacción", "Cerrar", { duration: 3000 })
        }
        else {
          window.location.href = response.data.url;
        }
        spinner?.hide();
      })
    .catch((error: HttpErrorResponse) => {
        console.log(error);
        snackbar?.open("Algo salió mal al iniciar la transacción", "Cerrar", { duration: 3000 })
        spinner?.hide();
      });
    }
}
