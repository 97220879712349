import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-balance-recharge-modal',
  templateUrl: './balance-recharge-modal.component.html',
  styleUrls: ['./balance-recharge-modal.component.css']
})
export class BalanceRechargeModalComponent {
  @Output() balanceRecharge = new EventEmitter<number>();
  amount: number = 0;
  minimumRechargeAmount: number = environment.minimumRechargeAmount;
  maximumRechargeAmount: number = environment.maximumRechargeAmount;

  onSend() {
    if (this.amount >= this.minimumRechargeAmount && this.amount <= this.maximumRechargeAmount) {
      this.balanceRecharge.emit(this.amount);
    }
  }
}
