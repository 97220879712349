import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User, PaymentMethod, Store } from 'src/app/interfaces';
import { VerificationChargeInfoComponent } from '../verification-charge-info/verification-charge-info.component';

export interface ConfirmOpenData {
  paymentMethodValidated: boolean;
  requireSecurityCode: boolean;
  securityCode: string;
  user: User;
  store: Store;
}

@Component({
  selector: 'app-confirm-open',
  templateUrl: './confirm-open.component.html',
  styleUrls: ['./confirm-open.component.scss']
})
export class ConfirmOpenComponent implements OnInit {

  paymentMethodValidated: boolean;
  requireSecurityCode: boolean;
  securityCode: string;
  hiddenSecurityCode: string = "";
  user: User;
  store: Store;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOpenData) {
      let paymentMethodValidated: boolean = false;
      if (Object.keys(this.data.user.paymentMethods).includes(PaymentMethod.TRANSBANK)) {
        paymentMethodValidated = this.data.user.paymentMethodValidated!;
      }
      this.user = data.user;
      this.store = data.store;
      if (Object.keys(this.data.user.paymentMethods).includes(PaymentMethod.MERCADOPAGO)) {
        if (this.data.user.paymentMethods?.[PaymentMethod.MERCADOPAGO]?.[this.store.marketplaceId]) {
          paymentMethodValidated = this.data.user.mercadopago2Validated![this.store.marketplaceId];
        }
      }
      this.paymentMethodValidated = paymentMethodValidated;
      // TODO: If the user has a valid method that doesn't require the security code, then we shouldn't ask for it
      this.requireSecurityCode = data.requireSecurityCode;
      this.securityCode = data.securityCode;
      if (this.securityCode) {
        this.hiddenSecurityCode = "*".repeat(this.securityCode.length);
      }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(VerificationChargeInfoComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log("closed")
    });
  }

  onSecurityCodeChange(event: any) {
    this.hiddenSecurityCode = "*".repeat(this.securityCode.length);
  }

  close() {
    this.dialogRef.close();
  }

  confirmOpen() {
    this.dialogRef.close({securityCode: this.securityCode});
  }

}
