<h2 mat-dialog-title class="text-dialog-dark" *ngIf="useCardInscription">¡Ten tu tarjeta a mano!</h2>
<h2 mat-dialog-title class="text-dialog-dark" *ngIf="!useCardInscription">¡Recarga tu saldo!</h2>
<div mat-dialog-content>
  <ng-container *ngIf="useCardInscription">
    <div class="text-neutral-600 text-sm font-medium text-justify py-2">
      {{ transbankAccepted && mercadopagoAccepted ?
        'Haz click en la opción que prefieras para registrar tu tarjeta de manera segura.':
        'Haz click en el botón para comenzar a registrar tu tarjeta de manera segura.'}}
    </div>
    <div *ngIf="mercadopagoAccepted" [mat-dialog-close]="true" class="flex justify-between h-24 my-2 p-3 rounded-lg border-solid border-1 border-neutral-300 hover:bg-neutral-300 hover:cursor-pointer" (click)="mercadopagoInscription()">
      <div class="flex items-center content-center">
        <img width="60px" src="assets/img/mercadopago_logo.png">
      </div>
      <div class="flex flex-col justify-evenly">
        <h4 class="text-black text-right text-sm m-0">Tarjeta de crédito o débito</h4>
        <p class="text-right text-black text-xs m-0">Tiempo estimado: 1 minuto</p>
      </div>
    </div>
    <div *ngIf="transbankAccepted" [mat-dialog-close]="true" class="flex justify-between h-24 my-2 p-3 rounded-lg border-solid border-1 border-neutral-300 hover:bg-neutral-300 hover:cursor-pointer" (click)="transbankInscription()">
      <div class="flex items-center content-center">
        <img class="h-full object-contain px-0 py-1" width="60px" src="assets/img/webpay_logo.png">
      </div>
      <div class="flex flex-col justify-evenly">
        <h4 class="text-black text-right text-sm m-0">Tarjeta de crédito o débito</h4>
        <p class="text-right text-black text-xs m-0">Tiempo estimado: 3 minutos</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="saldoreiteAccepted && useAmipass">
    <!-- <mat-divider class="m-2"></mat-divider> -->
    <h2 class="text-dark font-secondary text-xl" *ngIf="useCardInscription">O bien</h2>
    <div class="text-neutral-600 text-sm font-medium text-justify py-2">
      Recarga tu saldo personal con los siguientes medios de pago.
    </div>
    <div *ngIf="saldoreiteAccepted" [mat-dialog-close]="true" class="flex justify-between h-24 my-2 p-3 rounded-lg border-solid border-1 border-neutral-300 hover:bg-neutral-300 hover:cursor-pointer" (click)="openBalanceRechargeDialog()">
      <div class="flex items-center content-center">
        <img class="h-full object-contain px-0 py-1" width="60px" src="assets/img/amipass.png">
      </div>
      <div class="flex flex-col justify-evenly">
        <h4 class="text-black text-right text-sm m-0">Recargar con Amipass</h4>
        <p class="text-right text-black text-xs m-0">Tiempo estimado: 3 minutos</p>
      </div>
    </div>
  </ng-container>
</div>
