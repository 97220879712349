import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  unixTimestampToDate(timestamp: number) {
    let date = new Date(timestamp * 1000);
    return date.toLocaleDateString('es-CL', {
      day : 'numeric',
      month : 'short',
      year : 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
      });
  }

  parsePrice(price: number ) {
    if (price >= 0) {
      return `$${price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    }
    else {
      return `-$${Math.abs(price).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    }
  }
}
