import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthProvider, PhoneAuthProvider, RecaptchaVerifier, getAuth, OAuthProvider, User, linkWithCredential, PhoneAuthCredential } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SessionStorageService } from './session-storage.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  reCaptchaVerifier: RecaptchaVerifier | undefined = undefined;

  constructor(
    private auth: AngularFireAuth,
    public router: Router,
    private sessionStorage: SessionStorageService,
  ) {}

  setFailedCredential(cred: any): void {
    localStorage.setItem('failedCredential', JSON.stringify(cred));
  }

  getFailedCredential(): any {
    let item = localStorage.getItem('failedCredential');
    if (item === null) {
      return null;
    }
    const credentialObject = JSON.parse(item);
    let credential: firebase.default.auth.AuthCredential
    if (credentialObject.providerId === 'microsoft.com') {
      credential = OAuthProvider.credentialFromJSON(credentialObject);
    }
    else if (credentialObject.providerId === 'google.com') {
      credential = GoogleAuthProvider.credential(null, credentialObject.accessToken);
    }
    else {
      throw new Error('Unsuported provider');
    }
    return credential;
  }

  setFailedEmail(email: string): void {
    localStorage.setItem('email', email);
  }

  getFailedEmail(): string {
    let item = localStorage.getItem('email');
    if (item === null) {
      return '';
    }
    return item;
  }

  deleteFailedCredentialAndEmail() {
    localStorage.removeItem('failedCredential');
    localStorage.removeItem('email');
  }

  loginInApp(user: firebase.default.User){
    return user.getIdToken(false);
  }

  loginWithEmailAndPassword(email: string, password: string){
    try {
      return this.auth.signInWithEmailAndPassword(email, password);
    }
    catch(err) {
      console.log("Login error: ", err)
      return null;
    }
  }

  getRecaptchaVerifier() {
    let authInstance = getAuth();
    if (this.reCaptchaVerifier === undefined) {
      this.reCaptchaVerifier = new RecaptchaVerifier('sign-in-button',
      {'size': 'invisible'},
      authInstance);
    }
    return this.reCaptchaVerifier;
  }

  getOTP(phoneNumber: string) {
    let reCaptchaVerifier = this.getRecaptchaVerifier();
    return this.auth.signInWithPhoneNumber(phoneNumber, reCaptchaVerifier)
  }

  loginWithPhone(verificationId: string, verificationCode: string) {
    var credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
    return this.auth.signInWithCredential(credentials);
  }

  addPhoneToUser(verificationId: string, verificationCode: string) {
    var credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
    return this.auth.currentUser.then(user => user!.updatePhoneNumber(credentials));
  }

  loginWithCustomToken(token: string) {
    return this.auth.signInWithCustomToken(token);
  }

  registerWithEmailAndPassword(email: string, password: string) {
    try {
      return this.auth.createUserWithEmailAndPassword(email, password);
    }
    catch(err) {
      console.log("Login error: ", err)
      return null;
    }
  }

  getEmailProvider(providerId: string, email?: string) {
    let provider;
    switch(providerId) {
      case 'password':
        return null;
      case 'google.com':
        provider = new GoogleAuthProvider();
        break;
      case 'microsoft.com':
        provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
          TENANT_ID: '0bf7af2d-2da9-47c0-84bf-bd76abe61c00'
        })
        break;
      default:
        throw new Error('Provider not found');
    }
    if (email) {
      provider.setCustomParameters({ login_hint: email });
    }
    return provider;
  }

  loginWithEmailProvider(providerId: string, email?: string) {
    const provider = this.getEmailProvider(providerId, email);
    if (provider === null) {
      this.router.navigate(['/login-email']);
      return null
    }
    else {
      return this.auth.signInWithPopup(provider);
    }
  }

  async linkProviderToExistingAccount(pendingCredential: any, existingUser: User) {
    try {
      const result = await linkWithCredential(existingUser, pendingCredential);
      return result;
    } catch (error) {
      console.error('Error al vincular proveedor:', error);
      throw error;
    }
  }

  fetchSignInMethodsForEmail(email: string) {
    return this.auth.fetchSignInMethodsForEmail(email);
  }

  sendEmailVerification(){
    return this.auth.user.subscribe(u => u!.sendEmailVerification());
  }


  updatePassword(email: string, password: string, newPassword: string){
    return this.loginWithEmailAndPassword(email, password)!.then(() => this.auth.user.subscribe(u => u!.updatePassword(newPassword)));
  }


  recoverPassword(email: string){
    return this.auth.sendPasswordResetEmail(email);
  }


  getUser() {
    return this.auth.user;
  }

  async logout(storeId: string = ''){
    this.auth.signOut().then(() => {
      this.sessionStorage.clearUser();
      this.router.navigateByUrl(`/login/${storeId}`);
    })
  }

  getUsersIdTokenResult() {
    return this.auth.idTokenResult;
  }

  // TODO: Remove this when the other PR is merged
  getPhoneAuthCredential(verificationId: string, verificationCode: string) {
    return PhoneAuthProvider.credential(verificationId, verificationCode);
  }

  updatePhoneNumber(credential: PhoneAuthCredential): Promise<void> {
    return this.auth.currentUser.then(user => user!.updatePhoneNumber(credential));
  }

}
