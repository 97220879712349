import { Component, Input, OnChanges, OnDestroy, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRipple } from '@angular/material/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmOpenComponent } from '../confirm-open/confirm-open.component';
import { APIDataResponse, IntegrationTransaction, OpenData, PaymentMethod, Store, StoreStatus, StoreType, User } from 'src/app/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CardInscriptionSelectionComponent } from '../card-inscription-selection/card-inscription-selection.component';
import { HttpService } from 'src/app/services/http.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { LoginReiteService } from 'src/app/services/login-reite.service';
import { MultiTransactionStateManagerService } from 'src/app/services/multi-transaction-state-manager.service';

declare var MP_DEVICE_SESSION_ID: any;

export interface ActionButton {
  // icon: string;
  label: string;
  id: number;
  disabled: boolean;
}

const VALID_PAYMENT_METHODS = Object.keys(environment.paymentMethods).filter((key) => environment.paymentMethods[key]);
const USER_MANAGED_PAYMENT_METHODS: string[] = [PaymentMethod.SALDO_REITE, PaymentMethod.TRANSBANK, PaymentMethod.MERCADOPAGO];

@Component({
  selector: 'app-action-button-footer',
  templateUrl: './action-button-footer.component.html',
  styleUrls: ['./action-button-footer.component.scss']
})
export class ActionButtonFooterComponent implements OnDestroy, OnChanges, AfterViewInit {

  @Input() user: User;
  @Input() store: Store;
  @Input() tokenCode: string;
  @ViewChild(MatRipple) ripple!: MatRipple;

  userLoaded: boolean = false;
  storeLoaded: boolean = false;
  isDarkTheme: boolean = environment.isDarkTheme;

  subscriptions: Subscription[] = [];
  cardRegistered: boolean = false;
  cardValid: boolean = false;
  canTakeAction: boolean = false;
  requireSecurityCode: boolean = false;
  securityCode: string | null = null;
  actionButton: ActionButton = {
    // icon: "",
    label: "",
    id: 0,
    disabled: false
  };

  showGlow: boolean = false;
  turnOffGlowTimeout: any;
  rippleInterval: any;
  rippleTimeout: any;
  openCooldown: any;

  constructor(
    public dialog: MatDialog,
    private firestore: FirestoreService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private analytics: AnalyticsService,
    private spinner: NgxSpinnerService,
    private http: HttpService,
    private loginReite: LoginReiteService,
    private multiTransactionStateManager: MultiTransactionStateManagerService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    clearTimeout(this.turnOffGlowTimeout);
    clearInterval(this.rippleInterval);
    clearTimeout(this.rippleTimeout);
    clearTimeout(this.openCooldown);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("user" in changes) {
      if (changes.user.currentValue !== undefined) {
        this.userLoaded = true;
      }
    }
    if ("store" in changes) {
      if (changes.store.currentValue) {
        this.storeLoaded = true;

      }
    }
    this.changeDataLoaded();
  }

  ngAfterViewInit() {
    this.rippleInterval = setInterval(() => {
      if (!this.actionButton.disabled) {
        this.triggerRipple();
      }
    }, 2000);
  }

  triggerRipple() {
    const rippleRef = this.ripple.launch({
      persistent: false, // Hace que el ripple sea persistente
      centered: true,   // Opción de centrar el ripple
      animation: {
        enterDuration: 1250, // Duración de la entrada
        exitDuration: 1250  // Duración de la salida
      }
    });
    this.rippleTimeout = setTimeout(() => {
      rippleRef.fadeOut();
    }, 4000);  // Tiempo de vida del ripple antes de desvanecerse
  }

  callToAction() {
    this.showGlow = false;
    switch (this.actionButton.id) {
      case 1:
        if (this.multiTransactionStateManager.autoConfirmEnabled) {
          this.autoConfirm();
        }
        else {
          this.openConfirmDialog();
        }
        break;
      case 2:
        this.goToCardInscription();
        break;
      case 3:
        this.goToLogin();
        break;
    }
  }

  autoConfirm() {
    if (this.multiTransactionStateManager.previousTransactionResults !== true) {
      this.securityCode = this.multiTransactionStateManager.previousTransactionResults?.securityCode;
    }
    this.openStore();
  }

  openConfirmDialog() {
    this.analytics.logEvent('open_confirm_open_dialog');
    const dialogRef = this.dialog.open(ConfirmOpenComponent, {
      data: {
        requireSecurityCode: this.requireSecurityCode,
        securityCode: this.securityCode,
        user: this.user,
        store: this.store
      },
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result !== true) {
          this.securityCode = result['securityCode'];
        }
        this.multiTransactionStateManager.enableAutoconfirm(result);
        this.openStore();
      }
      else {
        this.analytics.logEvent("close_open_store_dialog")
      }
    });
  }

  goToCardInscription() {
    const dialogRef = this.dialog.open(CardInscriptionSelectionComponent, {
      data: {
        user: this.user,
        acceptedPaymentMethods: this.store?.acceptedPaymentMethods,
        storeId: this.store?.storeId,
        origin: "profile"
      }
    });
  }

  openStore() {
    if (this.openCooldown) {
      return;
    }
    this.openCooldown = setTimeout(() => {
      this.clearCooldown();
    }, 1000);
    this.analytics.logEvent('open_store');
    this.spinner.show("cooler-opening");
    this.http.openStore(this.store?.storeId, this.securityCode, this.tokenCode, MP_DEVICE_SESSION_ID)
    .then((openResponse: APIDataResponse<OpenData>) => {
      if (openResponse.successful) {
        let transactionId = openResponse.data.transactionId;
        let superTransactionId = openResponse.data.superTransactionId;
        let doc = this.firestore.transactionDocumentReference(transactionId, superTransactionId);
        this.subscriptions.push(doc?.snapshotChanges().subscribe({
          next: (data) => {
            if (data.payload.exists) {
              let updatedData = data.payload.data() as IntegrationTransaction;
              const storeStatus = updatedData.storeStatus;
              if (storeStatus == StoreStatus.OPENED || storeStatus == StoreStatus.BUYING) {
                this.spinner.hide("cooler-opening");
                this.router.navigate(
                  ['message'],
                  { queryParams: { transactionId: transactionId, storeId: this.store?.storeId, superTransactionId: superTransactionId, storeType: this.store.storeType },
                    replaceUrl: true
                  }
                );
              }
              else if (storeStatus == StoreStatus.FAILED) {
                this.spinner.hide("cooler-opening");
                this._openSnackBar("Lo sentimos! No se pudo abrir la tienda.");
              }
            }
            else {
              this.analytics.logEvent('transaction_not_found');
              this.spinner.hide("cooler-opening");
              this._openSnackBar("Lo sentimos! No se pudo crear la transacción.");
            }
          },
          error: (error: HttpErrorResponse) => {
            this.analytics.logEvent('open_store_error');
            this.spinner.hide("cooler-opening");
            console.log(error.error.message);
          }
        }));
      }
      else {
        this.analytics.logEvent('open_store_error');
        this.spinner.hide("cooler-opening");
        this._openSnackBar("Lo sentimos! Hubo un problema comunicándonos con la tienda. Inténtalo denuevo más tarde");
      }
    })
    .catch((error: HttpErrorResponse) => {
      this.analytics.logEvent('open_store_deny_pending');
      this.spinner.hide("cooler-opening");
      if (error.status == 402) {
        this._openSnackBar("No podemos abrir la tienda porque tienes transacciones pendientes que no hemos podido cobrar. Revisa que tu método de pago tenga suficiente saldo e inténtalo nuevamente.");
        console.log(error.error)
      }
      else {
        this.analytics.logEvent('open_store_error');
        this._openSnackBar("Lo sentimos! Hubo un problema comunicándonos con la tienda. Inténtalo denuevo más tarde");
        console.log(error.error)
      }
    })
  }

  goToLogin() {
    this.analytics.logEvent('redirect_to_login');
    this.loginReite.openPopup();
    this.router.navigateByUrl(`login/${this.store?.storeId}`);
  }

  _openSnackBar(message: string) {
    let action = "Cerrar"
    this._snackBar.open(message, action, {
      duration: 10000,
      panelClass : "custom-snackbar"
    });
  }

  changeDataLoaded() {
    if (this.user !== undefined && this.user !== null && this.storeLoaded) {
      const acceptedPaymentMethodsKeys = Object.keys(this.store?.acceptedPaymentMethods);
      // Check if any acceptedPaymentMethodKey is in USER_MANAGED_PAYMENT_METHODS
      this.canTakeAction = acceptedPaymentMethodsKeys.some((key: string) => USER_MANAGED_PAYMENT_METHODS.includes(key)) &&
                           VALID_PAYMENT_METHODS.some((key: string) => USER_MANAGED_PAYMENT_METHODS.includes(key))
      acceptedPaymentMethodsKeys.forEach(paymentMethod => {
        if (Object.keys(this.user?.paymentMethods).includes(paymentMethod)) {
          if (this.user?.paymentMethods[paymentMethod] === PaymentMethod.MERCADOPAGO) {
            if (Object.keys(this.user?.paymentMethods[paymentMethod]!).includes(this.store?.marketplaceId)) {
              this.cardRegistered = true;
            }
          }
          else {
            this.cardRegistered = true;
          }
        }
      });
      if (this.cardRegistered) {
        for (let acceptedPaymentMethod of acceptedPaymentMethodsKeys) {
          if (
            acceptedPaymentMethod.includes('saldo') &&
            acceptedPaymentMethod !== PaymentMethod.SALDO_REITE &&
            this.user?.paymentMethods[acceptedPaymentMethod]?.active &&
            this.user?.paymentMethods[acceptedPaymentMethod]?.expiresAt*1000 > new Date().getTime() &&
            this.user?.paymentMethods[acceptedPaymentMethod]?.balance > 0
            ) {
            this.cardValid = true;
          }
        }
        // Balance managed by user
        if (
          acceptedPaymentMethodsKeys.includes(PaymentMethod.SALDO_REITE) &&
          Object.keys(this.user?.paymentMethods).includes(PaymentMethod.SALDO_REITE) &&
          this.user?.paymentMethods.saldo_reite?.active &&
          this.user?.paymentMethods.saldo_reite!.balance > environment.minimumValidateAmount
          ) {
          this.cardValid = true;
        }
        // Transbank
        if (
          acceptedPaymentMethodsKeys.includes(PaymentMethod.TRANSBANK) &&
          Object.keys(this.user?.paymentMethods).includes(PaymentMethod.TRANSBANK) &&
          this.user?.paymentMethods?.[PaymentMethod.TRANSBANK]!.length > 0
          ) {
          this.cardValid = true;
        }
        // Mercadopago
        else if (
          acceptedPaymentMethodsKeys.includes(PaymentMethod.MERCADOPAGO) &&
          Object.keys(this.user?.paymentMethods).includes(PaymentMethod.MERCADOPAGO) &&
          Object.keys(this.user?.paymentMethods[PaymentMethod.MERCADOPAGO]!).includes(this.store?.marketplaceId)
          ) {
          this.cardValid = true;
          const userTypeId = this.user?.paymentMethods[PaymentMethod.MERCADOPAGO]![this.store?.marketplaceId]!.cards[0].payment_method.payment_type_id!;
          this.requireSecurityCode = this.store?.acceptedPaymentMethods[PaymentMethod.MERCADOPAGO]!.requireSecurityCode[userTypeId];
        }
      }
    }
    if (this.user !== undefined) {
      if (this.user !== null) {
        if (!this.store?.active) {
          this.actionButton = {
            // icon: "lock",
            label: "TIENDA DESACTIVADA",
            id: 5,
            disabled: true
          }
        }
        else if (this.cardValid || this.store?.openType == "DEMO") {
          let storeType = this.store?.storeType || StoreType.SMART;
          let label = storeType != StoreType.COFFEE ? "ABRIR TIENDA" : "CONECTAR CON LA TIENDA";
          this.actionButton = {
            // icon: "lock_open",
            label: label,
            id: 1,
            disabled: false
          }
        }
        else if (this.canTakeAction) {
          this.actionButton = {
            // icon: "credit_card",
            label: "AÑADIR MEDIO DE PAGO",
            id: 2,
            disabled: false
          }
        }
        else {
          this.actionButton = {
            // icon: "lock",
            label: "SIN MÉTODO DE PAGO",
            id: 4,
            disabled: true
          }
        }
      }
      else {
        this.actionButton = {
          // icon: "login",
          label: "INICIAR SESIÓN",
          id: 3,
          disabled: false
        }
      }
    }
  }

  clearCooldown() {
    clearTimeout(this.openCooldown);
    this.openCooldown = null;
  }

  activateGlowEffect() {
    clearTimeout(this.turnOffGlowTimeout);
    this.showGlow = true;
    this.turnOffGlowTimeout = setTimeout(() => {
      this.showGlow = false;
    }, 3000);
  }
}
