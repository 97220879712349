<button
mat-raised-button
matRipple
class="relative w-full h-full bg-neutral-50 overflow-hidden"
#ripple="matRipple"
color="primary"
[disabled]="actionButton.disabled"
(click)="callToAction()"
id="action-button-footer"
[ngClass]="{'glow-effect': showGlow}"
>
  <ng-template #dataLoaded>
    {{ actionButton.label }}
  </ng-template>
  <ng-template #loadingData>
    <mat-spinner color="accent" diameter="28"></mat-spinner>
  </ng-template>
  <ng-container [ngTemplateOutlet]="(userLoaded && storeLoaded) ? dataLoaded : loadingData"/>
</button>
