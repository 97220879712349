<!-- Spinners -->
<ngx-spinner
    name="primary"
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium" color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="false"/>
<ngx-spinner
    name="cooler-opening"
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="false">
    <h2>{{ 'components.default.opening_store' | translate }}</h2>
</ngx-spinner>
<router-outlet></router-outlet>