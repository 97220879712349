import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteTrackerService {

  $storeId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event['routerEvent']) {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        const storeId = route.snapshot.paramMap.get('id') || route.snapshot.queryParamMap.get('storeId') || '';
        this.$storeId.next(storeId);
      }
    })
  }

  getStoreId(): Observable<string> {
    return this.$storeId.asObservable();
  }

  getStoreIdSnapshot(): string {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.paramMap.get('id') || route.snapshot.queryParamMap.get('storeId') || '';
  }

  goBack() {
    const storeId = this.getStoreIdSnapshot();
    if (storeId) {
      this.router.navigateByUrl(`catalog/${storeId}`);
    }
    else {
      this.router.navigateByUrl('home');
    }
  }
}
