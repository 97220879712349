export enum DeviceType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  DESKTOP = 'DESKTOP'
}

export enum BrowserType {
  CHROME = 'CHROME',
  SAFARI = 'SAFARI',
  FIREFOX = 'FIREFOX',
  EDGE = 'EDGE',
  OTHER = 'OTHER'
}

export interface PWAContext {
  isPWA: boolean;
  device: DeviceType;
  browser: BrowserType;
}

export interface CoolersOpen {
    cooler_id: string;
    request_timestamp: string;
}

export interface OpenData {
  transactionId: string;
  superTransactionId?: string | null;
}

export interface Cooler {
    cameras: string[];
    clientId: string;
    name: string;
    logoUrl: string;
    productsIds: string[];
}

export interface Phone {
  number: string;
  areaCode: string;
}


export interface Device {
    active: boolean;
    deviceId: string;
    isOpen: boolean;
    lastUserId: string;
    requestOpen: boolean;
    requestTimestamp: string;
}

export interface MercadoPagoCard {
  payment_method: {
    id: string;
    payment_type_id: "credit_card" | "debit_card";
  };
  last_four_digits: string;
}

export interface MercadoPagoUser {
  cards: MercadoPagoCard[];
}

export interface TransbankUser {
  card_number: string;
  card_type: string;

}

export enum PaymentMethod {
  TRANSBANK = "transbank",
  MERCADOPAGO = "mercadopago2",
  MERCADOPAGO_OLD = "mercadopago",
  SALDO_SODEXO = "saldo_sodexo",
  SALDO_EUROVENDING = "saldo_eurovending",
  SALDO_MASFRUTAS = "saldo_masfrutas",
  SALDO_CNV = "saldo_cnv",
  SALDO_REITE = "saldo_reite"
}

export enum StoreStatus {
  REQUESTED = "REQUESTED",
  OPENED = "OPENED",
  FAILED = "FAILED",
  BUYING = "BUYING",
  TIMEOUT = "TIMEOUT",
  CLOSED = "CLOSED",
}

export enum StoreType {
  SMART = "SMART",
  COFFEE = "COFFEE"
}

export interface PaymentMethods {
  transbank?: TransbankUser[];
  mercadopago2?: {[key: string]: MercadoPagoUser};
  saldo_reite?: Balance;
  saldo_cnv?: PeriodicBalance;
  saldo_eurovending?: PeriodicBalance;
  saldo_masfrutas?: PeriodicBalance;
  saldo_sodexo?: PeriodicBalance;
  saldo_sodexo2?: PeriodicBalance;
}
export interface Balance {
  active: boolean;
  balance: number;
  rechargeTimestamp: number;
}
export interface PeriodicBalance extends Balance {
  expiresAt: number;
}

export interface User {
    active: boolean;
    email?: string;
    phone?: {
      number: string;
      areaCode: string;
    };
    id: string;
    name: string;
    paymentMethodValidated?: boolean;
    mercadopago2Validated?: {[key: string]: boolean};
    paymentMethods: PaymentMethods;
    stores: string[];
    transactionsCount: number;
}

export interface APINoDataResponse {
  successful: boolean;
  message: string;
}

export interface APIDataResponse<T> extends APINoDataResponse {
  data: T;
}

export interface CatalogData {
  products: Product[];
  productsPacks: ProductsPack[];
}

export interface AmipassInitInput {
  amount: number;
  urlExito: string;
  urlFracaso: string;
}

export interface AmipassInitResponseData {
  url: string;
  token: string;
  idpayment: number;
  ts: number;
}

export interface TransbankAuthorizedResponse {
    accounting_date: string;
    buy_order: string;
    card_detail: {
        card_number: string;
    };
    details: {
        amount: number;
        authorization_code: string;
        buy_order: string;
        commerce_code: string,
        installments_number: number;
        payment_type_code: string;
        response_code: number;
        status: string;
    }[];
    transaction_date: string;
}

export interface MercadoPagoAuthorizedResponse {
    id: number;
    date_created: string;
    date_approved: string;
    date_last_updated: string;
    date_of_expiration: string;
    money_release_date: string;
    operation_type: string;
    issuer_id: string;
    payment_method_id: string;
    payment_type_id: string;
    status: string;
    status_detail: string;
    currency_id: string;
    description: string;
    live_mode: boolean;
    sponsor_id: string;
    authorization_code: string;
    money_release_schema: string;
    taxes_amount: number;
    counter_currency: string;
    shipping_amount: number;
    pos_id: string;
    store_id: string;
    collector_id: number;
    payer: {[key: string]: any}
    metadata: {[key: string]: any}
    additional_info: {[key: string]: any}
    external_reference: string;
    transaction_amount: number;
    transaction_amount_refunded: number;
    coupon_amount: number;
    differential_pricing_id: string;
    deduction_schema: string;
    transaction_details: {[key: string]: any}
    fee_details: any[];
    captured: boolean;
    binary_mode: boolean;
    call_for_authorize_id: string;
    statement_descriptor: string;
    installments: number;
    card: {[key: string]: any}
    notification_url: string;
    processing_mode: string;
    merchant_account_id: string;
    acquirer: string;
    merchant_number: string;
    point_of_interaction: {[key: string]: any}
}

export interface TransbankRefundedResponse {
    authorization_code: string;
    authorization_date: string;
    balance: number;
    buy_order: string;
    nullified_amount: number;
    response_code: number;
    type: string;
}

export interface MercadoPagoRefundedResponse {
    id: number;
    payment_id: number;
    amount: number;
    metadata: any[];
    source: string[];
    date_created: string;
    unique_sequence_number: string;
    refund_mode: string;
    adjustment_amount: number;
    status: string;
    reason: string;
    label: any[];
    partition_details: any[];
}

export interface IntegrationTransaction {
    amount?: number;
    clientId: string;
    id: string;
    paymentMethod?: string;
    paymentResponses?: {
        authorized : TransbankAuthorizedResponse[] | MercadoPagoAuthorizedResponse[],
        refunded: TransbankRefundedResponse[] | MercadoPagoRefundedResponse[],
        rejected: any[]
    };
    requestTimestamp: number;
    results?: {
        products: Product[];
    };
    status?: string;
    store: Store;
    storeId: string;
    storeStatus: StoreStatus;
    rating?: number;
    ratingComments?: string;
    userId: string;
}

export interface Rating {
  rating: number;
  comments: string;
}

export interface TwilioVerifyResponseData {
  customToken: string;
}

export interface PhoneEvent {
  phoneNumber: string;
  dialCode: string;
  completePhoneNumber: string;
}

export interface AcceptedPaymentMethods {
    mercadopago2?: {
      requireSecurityCode: {
        debit_card: boolean;
        credit_card: boolean;
      }
    };
    transbank?: {[key: string]: any};
    reite_payment?: {}
}

export interface Store {
    active: boolean;
    clientId?: string;
    storeId: string;
    marketplaceId?: string;
    name: string;
    logoUrl?: string;
    logoUrlDark?: string;
    logoUrlWhite?: string;
    storeVideo?: string;
    acceptedPaymentMethods: AcceptedPaymentMethods;
    createAmount?: number;
    validationAmount?: number;
    openType?: string;
    storeType?: StoreType;
}

export interface UpdateUserResponseData {
  user: User;
  customToken: string;
}

export interface Coupons {
  [key:string] : Coupon
}

export interface Coupon {
    category: string;
    description: string;
    discount: number;
    endTimestamp: number;
    id: string;
    limitPerUser: number;
    maxDiscount?: number;
    minAmount?: number;
    startTimestamp: number;
    stores?: [string];
    timesUsedByUser: number;
    type: string;
}

export interface SkuBefore {
    code: string;
    quantity: number;
}

export interface SkuAfter {
    code: string;
    quantity: number;
}

export interface SkuPuchased {
    code: string;
    quantity: number;
    price: number;
}


export interface ValidatedTransaction {
    amount: number;
    closeTimestamp: number;
    coolerId: string;
    imagePaths: {
        [key: string]: {
            [key: string]: string
        }
    };
    lastUserId: string;
    openTimestamp: number;
    paymentResponses: any[];
    rating?: number;
    requestOpen: boolean;
    requestTimestamp: string;
    results: {
        [key: string]: {
            skusPurchased: SkuPuchased[],
            skusBefore: SkuBefore[],
            skusAfter: SkuAfter[],
        },
    };
    status: string;
    transactionId: string;
    transactionNumber: string;
    transactionTime: number;
    validated: boolean;
}

export interface ProductWithFeature {
    company: string;
    features: string;
    id: string;
    image_url: string;
    thumbnail_url: string;
    name: string;
    price: number;
    size: string;
}

export interface ProductWithoutFeature {
    company: string;
    id: string;
    image_url: string;
    thumbnail_url: string;
    name: string;
    price: number;
    size: string;
}

export interface Product {
  category: string;
  company: string;
  features?: string;
  size?:string;
  quantity: number;
  id: string;
  image_url: string;
  thumbnail_url: string;
  name: string;
  price: number;
  discount?: Discount;
}

export interface Discount {
  endTimestamp: number;
  startTimestamp: number;
  type: string;
  value: number;
}

export interface ProductsPack {
  id: string
  name: string;
  price: number;
  products: { [productId: string]: {
    quantity: number;
    price: number;
    name: string;
    }
  };
  startTimestamp: number;
  endTimestamp: number;
  currentUsage?: number;
  usageLimit: number | null;
  imageUrl: string;
}

export interface FAQ {
  answer: string;
  category: string;
  clientId: string;
  createdAt: number;
  id: string;
  question: string;
}

export interface LoginUrlResponseData {
  url: string;
  code_verifier: string;
  hashed_code_verifier: string;
  state: string;
  scope: string;
}

export interface TnCSection {
  title: string;
  content: {
    "type": "p" | "ul";
    "text"?: string;
    "items"?: string[];
  }[];
}

export interface CountryCode {
  name: string;
  dial_code: string;
  code: string;
  src: string;
}

export interface SideBarLink {
  icon: string;
  title: string;
  action: () => void;
}

export enum OTPChannels {
  SMS = "sms",
  WHATSAPP = "whatsapp"
}

export enum ReCaptchaV3ExecutionCodes {
  EXISTS = "EXISTS",
  LOGIN = "LOGIN"
}
