<div class="flex flex-col justify-center items-center p-2 w-full">
  <div class="flex justify-center items-center w-full">
    <mat-form-field appearance="outline" class="w-full p-0 m-1 max-w-28">
      <mat-label>Código</mat-label>
      <mat-select panelWidth="" [(value)]="selectedCountryCode">
        <mat-select-trigger>
          <img with="21px" height="21px" src="{{ selectedCountryCode.src }}">
        </mat-select-trigger>
        <mat-option [value]="countryCode" *ngFor="let countryCode of countryCodes">
          <img with="21px" height="21px" src="{{ countryCode.src }}"> {{ countryCode.dial_code }} {{ countryCode.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-full p-0 m-1" floatLabel="always">
      <mat-label>Número de teléfono</mat-label>
      <span matTextPrefix class="pr-1">{{ selectedCountryCode.dial_code }}</span>
      <input (click)="onPhoneNumberTouched()" type="number" inputmode="numeric" matInput placeholder="912345678" (ngModelChange)="onPhoneNumberChange($event)" [formControl]="phoneControl" maxLength="12">
      <mat-error *ngIf="error">Número ocupado.</mat-error>
    </mat-form-field>
  </div>
  <button mat-raised-button color="primary" class="flex items-center justify-center w-full m-2" (click)="sendPhoneNumber()">
    <span class="flex items-center">
      <img src="assets/img/sms-white-24.png" height="24px">
      <span class="mx-3">Obtener código por SMS</span>
    </span>
  </button>
</div>
